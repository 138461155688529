/**
 * Function: formatCurrency
 * Floating point number to fixed precision only if has decimals
 * @param {number} value
 * @param {number} precision
 * @returns {string}
 */
export const formatCurrency = (value: number, precision: number = 2) => {
  if (Math.round(value) !== value) {
    return value.toFixed(precision);
  }
  return value.toString();
};
