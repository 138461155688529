import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useParams } from "react-router-dom";

// Components
import Loader from "../components/Loader";
import EditAccount from "../components/Account/modals/EditAccount";
import OrdersTable from "../components/Orders";
import BankAccounts from "../components/BankAccounts";

const USER_QUERY = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      type
      company_name
      firstname
      lastname
      email
      phone
      street_name
      street_number
      zip_code
      city
      country
      nationality
      birth
      verified
      verified_expiry
      created_at
      updated_at
      orders {
        id
        date
        from_currency
        from_amount
        to_currency
        to_amount
        status {
          name
        }
        status_reason {
          name
        }
        bank_account {
          iban
          beneficiary
        }
      }
      bank_accounts {
        iban
        currency_id
        bank_name
        beneficiary
        bic_code
        active
      }
    }
  }
`;

const User = () => {
  const { t } = useTranslation();

  const { id } = useParams<any>();

  const { error, loading, data, refetch } = useQuery(USER_QUERY, {
    variables: { id },
  });

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.user === null) {
    return <Redirect to="/" />;
  }

  const { user } = data;

  const { orders, bank_accounts } = user;

  return (
    <div className="container">
      <div className="my-2 d-flex align-items-center">
        <Link to="/users">
          <div className="btn btn-sm">
            <i className="bi bi-arrow-left-short fs-2"></i>
          </div>
        </Link>

        <h2 className="mb-0">Utente</h2>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <small className="text-muted">
                {t(`home.type.${user.type}`)}
              </small>
              {user.type === "company" && <h5>{user.company_name}</h5>}
              <br />
              <h5 className="card-title">{`${user.firstname} ${user.lastname}`}</h5>
              <h6 className="mb-2 card-subtitle text-muted">{user.email}</h6>
              {user.phone && (
                <p className="mb-2">
                  <b>{user.phone}</b>
                </p>
              )}
              <p className="card-text">
                {(user.street_name || user.street_number) &&
                  `${user.street_name || ""} ${user.street_number || ""}`}
                <br />
                {(user.zip_code || user.city) &&
                  `${user.zip_code || ""} ${user.city || ""}`}
              </p>
              <div className="gap-2 d-grid">
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_account"
                >
                  {t("home.edit_account")}
                </button>
              </div>
            </div>
          </div>
          <BankAccounts bank_accounts={bank_accounts} refetch={refetch} />
        </div>
        <div className="col-sm">
          <div className="card mb-3">
            <div className="card-body">
              <h3 className="mb-3">Ordini</h3>
              <OrdersTable orders={orders} role="admin" />
            </div>
          </div>
        </div>
      </div>
      <EditAccount user={user} role="admin" refetch={refetch} />
    </div>
  );
};

export default User;
