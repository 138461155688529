import React, { useState } from "react";

import { useMutation, gql, useApolloClient } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import checkAuth from "../utils/checkAuth";
import Loader from "../components/Loader";

const REGISTER_MUTATION = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
      }
    }
  }
`;

const Register = () => {
  const { t } = useTranslation();

  const client = useApolloClient();

  const [register, { data, error, loading }] = useMutation(REGISTER_MUTATION);

  const initialState = {
    type: "",
    company_name: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    street_name: "",
    street_number: "",
    zip_code: "",
    city: "",
    country: "",
    nationality: "",
    birth: "",
  };

  const confirmInitialState = {
    email_confirm: "",
    password_confirm: "",
  };

  const [form, setForm] = useState(initialState);
  const [confirm, setConfirm] = useState(confirmInitialState);

  if (checkAuth()) {
    return <Redirect to="/" />;
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm({ ...confirm, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    register({ variables: { input: form } });
  };

  if (error) {
    localStorage.removeItem("token");
    return (
      <div className="alert alert-danger container mt-3">
        Error connecting to database
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.register.jwt) {
    localStorage.setItem("token", data.register.jwt);
    client.writeQuery({
      query: gql`
        query IsAuth {
          isAuthenticated @client
        }
      `,
      data: {
        isAuthenticated: true,
      },
    });

    return <Redirect to="/" />;
  }

  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="card shadow-sm w-100 my-5" style={{ maxWidth: "550px" }}>
        <div className="card-body">
          {data && data.register.jwt === null && (
            <div className="alert alert-danger">{t("User.already_exists")}</div>
          )}
          {form.type === "" ? (
            <div>
              <h3 className="text-center">{t("register.title")}</h3>

              <div
                className="d-flex justify-content-evenly align-items-center gap-2"
                style={{ height: 150 }}
              >
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => {
                    setForm({ ...form, type: "personal" });
                  }}
                >
                  {t("home.type.personal")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => {
                    setForm({ ...form, type: "company" });
                  }}
                >
                  {t("home.type.company")}
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {form.type === "personal" && (
                <>
                  <h3 className="text-center">{t("home.type.personal")}</h3>

                  <div className="row g-2 mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="firstname" className="form-label">
                        {t("User.firstname")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        value={form.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="lastname" className="form-label">
                        {t("User.lastname")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        value={form.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="birth" className="form-label">
                        {t("User.birth")}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="birth"
                        value={form.birth}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="phone" className="form-label">
                        {t("User.phone")}
                      </label>
                      <input
                        type="phone"
                        className="form-control"
                        id="phone"
                        value={form.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-7">
                      <label htmlFor="street_name" className="form-label">
                        {t("User.street_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="street_name"
                        value={form.street_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="street_number" className="form-label">
                        {t("User.street_number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="street_number"
                        value={form.street_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="zip_code" className="form-label">
                        {t("User.zip_code")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zip_code"
                        value={form.zip_code}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="city" className="form-label">
                        {t("User.city")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        value={form.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="country" className="form-label">
                        {t("User.country")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        value={form.country}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="nationality" className="form-label">
                        {t("User.nationality")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        value={form.nationality}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="email" className="form-label">
                        {t("User.email")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={form.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="email_confirm" className="form-label">
                        {t("User.email_confirm")}
                      </label>
                      <input
                        type="email_confirm"
                        className="form-control"
                        id="email_confirm"
                        value={confirm.email_confirm}
                        onChange={handleConfirmChange}
                        onBlur={(e) => {
                          if (form.email !== e.target.value) {
                            e.target.classList.add("is-invalid");
                          } else {
                            e.target.classList.remove("is-invalid");
                          }
                        }}
                        required
                      />
                      <div className="invalid-feedback">
                        {t("User.email_confirm_invalid")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="password" className="form-label">
                        {t("User.password")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={form.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="password_confirm" className="form-label">
                        {t("User.password_confirm")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password_confirm"
                        value={confirm.password_confirm}
                        onChange={handleConfirmChange}
                        onBlur={(e) => {
                          if (form.password !== e.target.value) {
                            e.target.classList.add("is-invalid");
                          } else {
                            e.target.classList.remove("is-invalid");
                          }
                        }}
                        required
                      />
                      <div className="invalid-feedback">
                        {t("User.password_confirm_invalid")}
                      </div>
                    </div>

                    <b>{t("register.declare")}</b>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("register.economic_rights")}
                      </label>
                    </div>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        id="terms_confirm"
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("register.not_pep_us_person")}
                      </label>
                    </div>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("home.terms_confirm.before")}
                        <a
                          href={t("company.terms_link")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("home.terms_confirm.link")}
                        </a>
                        {t("home.terms_confirm.after")}
                      </label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-block">
                      {t("register.submit_button")}
                    </button>
                  </div>
                </>
              )}
              {form.type === "company" && (
                <>
                  <h3 className="text-center">{t("home.type.company")}</h3>
                  <div className="row g-2 mb-3">
                    <div className="col-sm-12">
                      <label htmlFor="company_name" className="form-label">
                        {t("User.company_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        value={form.company_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row g-2 mb-3">
                    <div className="col-sm-7">
                      <label htmlFor="street_name" className="form-label">
                        {t("User.street_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="street_name"
                        value={form.street_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="street_number" className="form-label">
                        {t("User.street_number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="street_number"
                        value={form.street_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="zip_code" className="form-label">
                        {t("User.zip_code")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zip_code"
                        value={form.zip_code}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="city" className="form-label">
                        {t("User.city")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        value={form.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="country" className="form-label">
                        {t("User.country")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        value={form.country}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="phone" className="form-label">
                        {t("User.phone")}
                      </label>
                      <input
                        type="phone"
                        className="form-control"
                        id="phone"
                        value={form.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="email" className="form-label">
                        {t("User.email")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={form.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="email_confirm" className="form-label">
                        {t("User.email_confirm")}
                      </label>
                      <input
                        type="email_confirm"
                        className="form-control"
                        id="email_confirm"
                        value={confirm.email_confirm}
                        onChange={handleConfirmChange}
                        onBlur={(e) => {
                          if (form.email !== e.target.value) {
                            e.target.classList.add("is-invalid");
                          } else {
                            e.target.classList.remove("is-invalid");
                          }
                        }}
                        required
                      />
                      <div className="invalid-feedback">
                        {t("User.email_confirm_invalid")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="password" className="form-label">
                        {t("User.password")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={form.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="password_confirm" className="form-label">
                        {t("User.password_confirm")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password_confirm"
                        value={confirm.password_confirm}
                        onChange={handleConfirmChange}
                        onBlur={(e) => {
                          if (form.password !== e.target.value) {
                            e.target.classList.add("is-invalid");
                          } else {
                            e.target.classList.remove("is-invalid");
                          }
                        }}
                        required
                      />
                      <div className="invalid-feedback">
                        {t("User.password_confirm_invalid")}
                      </div>
                    </div>
                    <h5 className="mb-0 mt-4">
                      {t("register.contact_person")}
                    </h5>
                    <div className="col-sm-6">
                      <label htmlFor="firstname" className="form-label">
                        {t("User.firstname")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        value={form.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="lastname" className="form-label">
                        {t("User.lastname")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        value={form.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="birth" className="form-label">
                        {t("User.birth")}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="birth"
                        value={form.birth}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="nationality" className="form-label">
                        {t("User.nationality")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="nationality"
                        value={form.nationality}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {form.type === "company" && (
                      <div className="mt-3">
                        <p>{t("User.company_documents_text")}</p>
                        <ul>
                          <li>{t("User.company_documents.0")}</li>
                          <li>{t("User.company_documents.1")}</li>
                          <li>
                            <a href="/formulario-k.pdf" target="_blank">
                              {t("User.company_documents.2")}
                            </a>
                          </li>
                          <li>{t("User.company_documents.3")}</li>
                        </ul>
                      </div>
                    )}
                    <b>{t("register.declare")}</b>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("register.economic_rights")}
                      </label>
                    </div>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        id="terms_confirm"
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("register.not_member_pep_us_person")}
                      </label>
                    </div>
                    <div className="form-check ms-1 col-sm-12">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="terms_confirm"
                      >
                        {t("home.terms_confirm.before")}
                        <a
                          href={t("company.terms_link")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("home.terms_confirm.link")}
                        </a>
                        {t("home.terms_confirm.after")}
                      </label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-block">
                      {t("register.submit_button")}
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
