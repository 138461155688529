import { useTranslation } from "react-i18next";
import ChangePassword from "./modals/ChangePassword";
import EditAccount from "./modals/EditAccount";

const Account = ({ me, refetch }: any) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className="card-body">
        <h3>{t("home.title")}</h3>
        <small className="text-muted">{t(`home.type.${me.type}`)}</small>
        {me.type === "company" && <h5>{me.company_name}</h5>}
        <br />
        <h5 className="card-title">{`${me.firstname} ${me.lastname}`}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{me.email}</h6>
        {me.phone && (
          <p className="mb-2">
            <b>{me.phone}</b>
          </p>
        )}

        <p className="card-text">
          {(me.street_name || me.street_number) &&
            `${me.street_name || ""} ${me.street_number || ""}`}
          <br />
          {(me.zip_code || me.city) && `${me.zip_code || ""} ${me.city || ""}`}
        </p>
        <div className="d-grid gap-2">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#edit_account"
          >
            {t("home.edit_account")}
          </button>
          <button
            className="btn btn-danger"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#change_password"
          >
            {t("home.change_password")}
          </button>
        </div>
      </div>
      <ChangePassword />
      <EditAccount user={me} refetch={refetch} />
    </div>
  );
};

export default Account;
