import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { i18n, t } = useTranslation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    setIsAuthenticated(token !== null);
    setIsAdmin(role === "admin");
  }, [pathname]);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);

    localStorage.setItem("lang", lang);
  };

  switch (pathname) {
    case "/converter":
      return <></>;
  }

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "#033878" }}
    >
      <div className="container p-2">
        <a className="navbar-brand" href="/">
          <img src="/logo.png" alt="Cambio Parini" width="250" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="mb-2 navbar-nav mb-lg-0">
            {isAuthenticated ? (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${pathname === "/" && "active"}`}
                    to="/"
                  >
                    {t("Header.account")}
                  </Link>
                </li>
                {isAdmin && (
                  <>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/orders" && "active"
                        }`}
                        to="/orders"
                      >
                        {t("Header.orders")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/users" && "active"
                        }`}
                        to="/users"
                      >
                        {t("Header.users")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          pathname === "/rates" && "active"
                        }`}
                        to="/rates"
                      >
                        {t("Header.rates")}
                      </Link>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Link className="nav-link" to="/logout">
                    {t("Header.logout")}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${pathname === "/login" && "active"}`}
                    to="/login"
                  >
                    {t("Header.login")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      pathname === "/register" && "active"
                    }`}
                    to="/register"
                  >
                    {t("Header.register")}
                  </Link>
                </li>
              </>
            )}

            <li className="nav-item">
              <select
                value={i18n.language}
                onChange={(e) => {
                  changeLanguage(e.target.value);
                }}
                className="form-select"
              >
                <option value="it">Italiano</option>
                <option value="de">Deutsch</option>
                <option value="fr">Francais</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
