import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  switch (pathname) {
    case "/converter":
      return <></>;
  }
  return (
    <footer className="p-5">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <p>
              <strong>{t("company.name")}</strong>
              <br />
              {t("company.address")}
              <br />
              {t("company.city")}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              {t("Footer.opening_hours", {
                returnObjects: true,
                joinArrays: ";",
              })
                .split(";")
                .map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
          <div className="col-md-3">
            <p>
              {t("company.phone")} <br />
              {t("company.email")}
            </p>
          </div>
          <div className="col-md-3">
            <a href={t("company.terms_link")}>{t("Footer.terms")}</a>
            <p>{t("Footer.copyright", { year: new Date().getFullYear() })}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
