import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client/react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./utils/i18n";

import client from "./utils/apollo";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner spinner-border"></div>
        </div>
      }
    >
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
