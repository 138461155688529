import { gql, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";

import OrdersTable from "../components/Orders";

// Components
import Loader from "../components/Loader";

const ORDERS_QUERY = gql`
  query OrdersQuery {
    orders {
      id
      date
      from_currency
      from_amount
      to_currency
      to_amount
      status {
        name
      }
      status_reason {
        name
      }
      bank_account {
        iban
        beneficiary
      }
    }
  }
`;

const Orders = () => {
  const { error, loading, data } = useQuery(ORDERS_QUERY);

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.orders === null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <h2 className="mt-3">Ordini</h2>
      <OrdersTable orders={data.orders} role="admin" />
    </div>
  );
};

export default Orders;
