import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, gql } from "@apollo/client";

const BANK_ACCOUNT_UPDATE = gql`
  mutation BankAccountUpdate($input: BankAccountUpdateInput!) {
    bankAccountUpdate(input: $input) {
      iban
    }
  }
`;

const EditBankAccount = ({ bank_account, refetch }: any) => {
  const { t } = useTranslation();

  const [bankAccountUpdate] = useMutation(BANK_ACCOUNT_UPDATE);

  const { currency_id, iban, bank_name, beneficiary, bic_code, active } =
    bank_account;

  const initialState = {
    currency_id,
    iban,
    bank_name,
    beneficiary,
    bic_code: bic_code || "",
    active,
  };

  const [form, setForm] = useState(initialState);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { data, errors } = await bankAccountUpdate({
      variables: { input: form },
    });

    if (errors) {
      console.log(errors);
    }

    if (data) {
      $(`#edit_bank_account_${iban}`).modal("hide");
      refetch();
    }
  };

  return (
    <div
      className="modal fade"
      id={`edit_bank_account_${iban}`}
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">{t("home.edit_bank_account")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor={`iban_${iban}`} className="form-label">
                  {t("BankAccount.iban")}
                </label>
                <input
                  id={`iban_${iban}`}
                  name="iban"
                  type="text"
                  className="form-control"
                  value={form.iban}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor={`currency_id_${iban}`} className="form-label">
                  {t("BankAccount.currency_id")}
                </label>
                <select
                  id={`currency_id_${iban}`}
                  name="currency_id"
                  className="form-select"
                  value={form.currency_id}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    {t("BankAccount.currency_id_placeholder")}
                  </option>
                  <option value="CHF">CHF</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor={`beneficiary_${iban}`} className="form-label">
                  {t("BankAccount.beneficiary")}
                </label>
                <input
                  id={`beneficiary_${iban}`}
                  name="beneficiary"
                  type="text"
                  className="form-control"
                  value={form.beneficiary}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor={`bank_name_${iban}`} className="form-label">
                  {t("BankAccount.bank_name")}
                </label>
                <input
                  id={`bank_name_${iban}`}
                  name="bank_name"
                  type="text"
                  className="form-control"
                  value={form.bank_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor={`bic_code_${iban}`} className="form-label">
                  {t("BankAccount.bic_code")}
                </label>
                <input
                  id={`bic_code_${iban}`}
                  name="bic_code"
                  type="text"
                  className="form-control"
                  value={form.bic_code}
                  onChange={handleChange}
                />
              </div>
              <div className="form-check">
                <input
                  id={`active_${iban}`}
                  name="active"
                  className="form-check-input"
                  type="checkbox"
                  checked={!form.active}
                  onChange={(e) => {
                    setForm({ ...form, active: !e.target.checked });
                  }}
                />
                <label className="form-check-label" htmlFor={`active_${iban}`}>
                  {t("BankAccount.active_checkbox")}
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("global.cancel")}
              </button>
              <button type="submit" className="btn btn-primary">
                {t("global.edit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditBankAccount;
