import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";

// Components
import Loader from "../components/Loader";

const ORDER_QUERY = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      date
      from_currency
      from_amount
      to_currency
      to_amount
      rate
      amount_received
      date_received
      amount_delivered
      date_delivered
      notes
      updated_at
      status {
        id
        name
      }
      status_reason {
        id
        name
      }
      bank_account {
        iban
        beneficiary
        bank_name
        bic_code
        currency_id
      }
      user {
        id
        firstname
        lastname
        email
        phone
        street_name
        street_number
        zip_code
        city
        country
        verified
      }
    }
    order_status {
      id
      name
    }
    order_status_reason {
      id
      name
    }
  }
`;

const ORDER_UPDATE = gql`
  mutation OrderUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      id
    }
  }
`;

const Order = () => {
  const { t } = useTranslation();

  const { id } = useParams<any>();

  const history = useHistory();

  const { error, loading, data } = useQuery(ORDER_QUERY, {
    variables: { id },
  });

  const [orderUpdate] = useMutation(ORDER_UPDATE);

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.order === null) {
    return <Redirect to="/" />;
  }

  const { order, order_status, order_status_reason } = data;

  const { user, bank_account } = order;

  const {
    amount_received,
    date_received,
    amount_delivered,
    date_delivered,
    status,
    status_reason,
    notes,
  } = order;

  const initialState = {
    amount_received: amount_received || 0,
    date_received: date_received
      ? moment.unix(date_received / 1000).format("YYYY-MM-DD")
      : "",
    amount_delivered: amount_delivered || 0,
    date_delivered: date_delivered
      ? moment.unix(date_delivered / 1000).format("YYYY-MM-DD")
      : "",
    status: status.name || "",
    status_reason: status_reason.name || "",
    notes: notes || "",
  };

  return (
    <div className="container">
      <div className="my-2 d-flex align-items-center">
        <Link to="/orders">
          <div className="btn btn-sm">
            <i className="bi bi-arrow-left-short fs-2"></i>
          </div>
        </Link>

        <h2 className="mb-0">Ordine #{order.id}</h2>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{`${user.firstname} ${user.lastname}`}</h5>
              <h6 className="mb-2 card-subtitle text-muted">{user.email}</h6>
              {user.phone && (
                <p className="mb-2">
                  <b>{user.phone}</b>
                </p>
              )}
              <p className="card-text">
                {(user.street_name || user.street_number) &&
                  `${user.street_name || ""} ${user.street_number || ""}`}
                <br />
                {(user.zip_code || user.city) &&
                  `${user.zip_code || ""} ${user.city || ""}`}
              </p>
              <div className="gap-2 d-grid">
                <Link to={`/users/${user.id}`} className="btn btn-primary">
                  Vai all'utente
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-3 card">
            <div className="card-body">
              <h5>Conto corrente</h5>
              <p>
                {bank_account.iban}
                <br />
                <b>Intestatario:</b> {bank_account.beneficiary}
                <br />
                <b>Banca:</b> {bank_account.bank_name}
                <br />
                <b>Valuta:</b> {bank_account.currency_id}
                {bank_account.bic_code && (
                  <>
                    <br />
                    <b>BIC:</b> {bank_account.bic_code}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialState}
                onSubmit={(values) => {
                  orderUpdate({
                    variables: {
                      input: {
                        id: order.id,
                        ...values,
                        date_delivered: values.date_delivered || undefined,
                        date_received: values.date_received || undefined,
                      },
                    },
                    refetchQueries: [{ query: ORDER_QUERY, variables: { id } }],
                  });

                  history.push("/orders");
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <label htmlFor="from_amount" className="form-label">
                        Da ({order.from_currency})
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="from_amount"
                        value={order.from_amount}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="to_amount" className="form-label">
                        A ({order.to_currency})
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="to_amount"
                        value={order.to_amount}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="amount_received" className="form-label">
                        Importo ricevuto ({order.from_currency})
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount_received"
                        onChange={handleChange}
                        value={values.amount_received}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="date_received" className="form-label">
                        Importo ricevuto il
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="date_received"
                        onChange={handleChange}
                        value={values.date_received}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="amount_delivered" className="form-label">
                        Importo inviato ({order.to_currency})
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="amount_delivered"
                        onChange={handleChange}
                        value={values.amount_delivered}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="date_delivered" className="form-label">
                        Importo inviato il
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="date_delivered"
                        onChange={handleChange}
                        value={values.date_delivered}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="status" className="form-label">
                        Stato
                      </label>
                      <select
                        id="status"
                        className="form-select"
                        defaultValue={values.status}
                        onChange={handleChange}
                      >
                        {order_status.map(({ id, name }: any) => (
                          <option key={id} value={name}>
                            {t(`Order.order_status.${name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="status_reason" className="form-label">
                        Motivazione
                      </label>
                      <select
                        id="status_reason"
                        className="form-select"
                        defaultValue={values.status_reason}
                        onChange={handleChange}
                      >
                        {order_status_reason.map(({ id, name }: any) => (
                          <option key={id} value={name}>
                            {t(`Order.order_status_reason.${name}`)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="notes" className="form-label">
                        Note
                      </label>
                      <textarea
                        className="form-control"
                        id="notes"
                        value={values.notes}
                        rows={2}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-12 d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary">
                        Salva
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card">
            <div className="card-body">
              <h5>Tasso</h5>
              <p>
                1 {order.from_currency} = {order.rate} {order.to_currency}
              </p>
              <h5>Creato il</h5>
              <p>
                {moment
                  .unix(order.date / 1000)
                  .format(t("global.datetime_format"))}
              </p>
              <h5>Ultima modifica</h5>
              <p>
                {moment
                  .unix(order.updated_at / 1000)
                  .format(t("global.datetime_format"))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
