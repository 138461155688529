import React, { useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import checkAuth from "../utils/checkAuth";

const REQUEST_RESET_MUTATION = gql`
  mutation RequestReset($input: RequestResetPasswordInput!) {
    requestResetPassword(input: $input) {
      success
    }
  }
`;

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [requestReset, { data, error }] = useMutation(REQUEST_RESET_MUTATION);

  const initialState = { email: "" };

  const [form, setForm] = useState(initialState);

  if (checkAuth()) {
    return <Redirect to="/" />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    requestReset({ variables: { input: form } });
  };

  if (error) {
    return (
      <div className="alert alert-danger container mt-3">
        Error connecting to database
      </div>
    );
  }

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: "70vh" }}
    >
      <div className="card shadow-sm w-100" style={{ maxWidth: "350px" }}>
        <div className="card-body">
          {data &&
            (data.requestResetPassword.success ? (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {t("forgotpassword.success")}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            ) : (
              <div className="alert alert-danger alert-dismissible fade show">
                {t("forgotpassword.error")}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            ))}
          <form onSubmit={handleSubmit}>
            <h3 className="text-center">{t("forgotpassword.title")}</h3>
            <p className="text-muted text-center">
              {t("forgotpassword.subtitle")}
            </p>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                {t("User.email")}
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-block">
                {t("forgotpassword.submit_button")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
