import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
    }
  }
`;

const ChangePassword = () => {
  const { t } = useTranslation();

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const initialState = {
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const [form, setForm] = useState(initialState);

  const [alert, setAlert] = useState("");

  const resetForm = () => {
    setForm(initialState);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { old_password, new_password, confirm_new_password } = form;

    if (new_password !== confirm_new_password) {
      return setAlert(t("ChangePassword.password_not_match"));
    }

    const { data, errors } = await changePassword({
      variables: {
        input: {
          old_password,
          new_password,
        },
      },
    });

    if (errors) {
      return setAlert("Error");
    }

    if (data.changePassword) {
      if (!data.changePassword.success) {
        return setAlert(t("ChangePassword.password_incorrect"));
      }

      setAlert("");

      $("#change_password").modal("hide");

      resetForm();
    }
  };

  return (
    <div
      className="modal fade"
      id="change_password"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">{t("home.change_password")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {alert && <div className="alert alert-danger">{alert}</div>}
              <div className="mb-3">
                <label htmlFor="old_password" className="form-label">
                  {t("ChangePassword.old_password")}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="old_password"
                  value={form.old_password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="new_password" className="form-label">
                  {t("ChangePassword.new_password")}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="new_password"
                  value={form.new_password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirm_new_password" className="form-label">
                  {t("ChangePassword.confirm_new_password")}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm_new_password"
                  value={form.confirm_new_password}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={resetForm}
              >
                {t("global.cancel")}
              </button>
              <button type="submit" className="btn btn-danger">
                {t("global.change")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
