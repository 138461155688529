import { useTranslation } from "react-i18next";

import { useMutation, gql } from "@apollo/client";

import { Formik, Form, Field } from "formik";
import moment from "moment";

const USER_UPDATE = gql`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      id
    }
  }
`;

type EditAccountProps = {
  user: any;
  refetch?: any;
  role?: string;
};

const EditAccount: React.FC<EditAccountProps> = ({
  user,
  refetch,
  role = "user",
}) => {
  const { t } = useTranslation();

  const [userUpdate] = useMutation(USER_UPDATE);

  const {
    id,
    type,
    company_name,
    firstname,
    lastname,
    email,
    phone,
    street_name,
    street_number,
    zip_code,
    city,
    country,
    verified,
    verified_expiry,
  } = user;

  const initialState: any = {
    id: id || "",
    type: type || "",
    company_name: company_name || "",
    firstname: firstname || "",
    lastname: lastname || "",
    email: email || "",
    phone: phone || "",
    street_name: street_name || "",
    street_number: street_number || "",
    zip_code: zip_code || "",
    city: city || "",
    country: country || "",
    verified: verified || false,
    verified_expiry: verified_expiry
      ? moment.unix(verified_expiry / 1000).format("YYYY-MM-DD")
      : "",
  };

  return (
    <div
      className="modal fade"
      id="edit_account"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <Formik
            initialValues={initialState}
            onSubmit={async (values) => {
              const { data, errors } = await userUpdate({
                variables: {
                  input: values,
                },
              });

              if (errors) {
                console.log(errors);
              }

              if (data) {
                if (refetch) refetch();
                $("#edit_account").modal("hide");
              }
            }}
          >
            {({ values }) => {
              return (
                <Form>
                  <div className="modal-header">
                    <h5 className="modal-title">{t("home.edit_account")}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {role === "admin" && (
                      <div className="row g-2">
                        <div className="mb-3 col-sm-6">
                          <label htmlFor="type" className="form-label">
                            {t("User.type")}
                          </label>
                          <Field
                            as="select"
                            className="form-control"
                            id="type"
                            name="type"
                          >
                            <option value="personal">
                              {t("home.type.personal")}
                            </option>
                            <option value="company">
                              {t("home.type.company")}
                            </option>
                          </Field>
                        </div>
                        {values.type === "company" && (
                          <div className="mb-3 col-sm-6">
                            <label
                              htmlFor="company_name"
                              className="form-label"
                            >
                              {t("User.company_name")}
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              id="company_name"
                              name="company_name"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="row g-2">
                      <div className="mb-3 col-sm">
                        <label htmlFor="firstname" className="form-label">
                          {t("User.firstname")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="firstname"
                          name="firstname"
                          disabled={role !== "admin"}
                        />
                      </div>
                      <div className="mb-3 col-sm">
                        <label htmlFor="lastname" className="form-label">
                          {t("User.lastname")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="lastname"
                          name="lastname"
                          disabled={role !== "admin"}
                        />
                      </div>
                    </div>
                    <div className="row g-2">
                      <div className="mb-3 col-sm">
                        <label htmlFor="email" className="form-label">
                          {t("User.email")}
                        </label>
                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          disabled={role !== "admin"}
                        />
                      </div>
                      <div className="mb-3 col-sm">
                        <label htmlFor="phone" className="form-label">
                          {t("User.phone")}
                        </label>
                        <Field
                          type="phone"
                          className="form-control"
                          id="phone"
                          name="phone"
                        />
                      </div>
                    </div>
                    <div className="row g-2">
                      <div className="mb-3 col-sm">
                        <label htmlFor="street_name" className="form-label">
                          {t("User.street_name")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="street_name"
                          name="street_name"
                        />
                      </div>
                      <div className="mb-3 col-sm-3">
                        <label htmlFor="street_number" className="form-label">
                          {t("User.street_number")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="street_number"
                          name="street_number"
                        />
                      </div>
                    </div>
                    <div className="row g-2">
                      <div className="mb-3 col-sm-3">
                        <label htmlFor="zip_code" className="form-label">
                          {t("User.zip_code")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="zip_code"
                          name="zip_code"
                        />
                      </div>
                      <div className="mb-3 col-sm-6">
                        <label htmlFor="city" className="form-label">
                          {t("User.city")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                        />
                      </div>
                      <div className="mb-3 col-sm-3">
                        <label htmlFor="country" className="form-label">
                          {t("User.country")}
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="country"
                          name="country"
                        />
                      </div>
                      {role === "admin" && (
                        <>
                          <div className="mx-1 col-sm-6 form-check flex">
                            <label
                              htmlFor="verified"
                              className="form-check-label"
                            >
                              {t("User.verified")}
                            </label>
                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="verified"
                              name="verified"
                            />
                          </div>
                          <div className="mb-3 col-sm">
                            <label
                              htmlFor="verified_expiry"
                              className="form-label"
                            >
                              {t("User.verified_expiry")}
                            </label>
                            <Field
                              type="date"
                              className="form-control"
                              id="verified_expiry"
                              name="verified_expiry"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      {t("global.cancel")}
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {t("global.edit")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditAccount;
