import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import Loader from "../components/Loader";

const RATES_QUERY = gql`
  query RatesQuery {
    currencies {
      id
      spread
    }
    rates(limit: 1152) {
      id
      from_currency
      to_currency
      rate
      date
      provider
    }
    isOnline: setting(name: "change_online") {
      value
    }
  }
`;

const CURRENCY_UPDATE = gql`
  mutation CurrencyUpdate($input: CurrencyUpdateInput!) {
    currencyUpdate(input: $input) {
      id
      spread
    }
  }
`;

const SETTING_UPDATE = gql`
  mutation SettingUpdate($input: SettingUpdateInput!) {
    settingUpdate(input: $input) {
      name
      value
    }
  }
`;

const Spread = ({ currency, current_rate }: any) => {
  const { t } = useTranslation();

  const [spread, setSpread] = useState(currency.spread);

  const [currencyUpdate] = useMutation(CURRENCY_UPDATE);

  return (
    <tr key={currency.id}>
      <td>1 {currency.id} =</td>
      <td className="p-1">{parseFloat(current_rate).toFixed(6)}</td>

      <td className="p-2" style={{ width: 100 }}>
        <input
          id={`rate_${currency.id}`}
          className="form-control text-end"
          value={spread}
          onChange={(e) => {
            setSpread(e.target.value);
          }}
        />
      </td>
      <td>
        <button
          className="btn btn-primary"
          onClick={() => {
            currencyUpdate({
              variables: {
                input: {
                  id: currency.id,
                  spread: parseFloat(spread),
                },
              },
            });
          }}
        >
          {t("global.edit")}
        </button>
      </td>
      <td className="p-2 text-end">
        <b>{(current_rate * (1 - spread / 100)).toFixed(6)}</b>
      </td>
    </tr>
  );
};

const Rates = () => {
  const { t, i18n } = useTranslation();

  const { error, loading, data, refetch } = useQuery(RATES_QUERY);

  const [settingUpdate] = useMutation(SETTING_UPDATE);

  useEffect(() => {
    let translationUrl =
      "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Italian.json";

    switch (i18n.language) {
      case "en":
        translationUrl =
          "https://cdn.datatables.net/plug-ins/1.10.22/i18n/English.json";
    }
    $("#rates").DataTable({
      order: [[0, "desc"]],
      language: {
        url: translationUrl,
      },
      destroy: true,
    });
  }, [i18n.language]);

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.rates === null) {
    return <Redirect to="/" />;
  }

  const { rates, currencies } = data;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <h2 className="mt-3">Gestione tassi</h2>
          <table>
            <thead>
              <tr>
                <th>Valuta</th>
                <th>Tasso mercato</th>
                <th className="text-center">Spread</th>
                <th></th>
                <th>Tasso effettivo</th>
              </tr>
            </thead>
            <tbody>
              {currencies.map((currency: any) => {
                const current_rate = rates.filter(
                  (rate: any) => rate.from_currency === currency.id
                )[0].rate;
                return (
                  <Spread
                    key={currency.id}
                    currency={currency}
                    current_rate={current_rate}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="col-sm">
          <h2 className="mt-3">Impostazioni</h2>

          <div className="d-flex gap-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                settingUpdate({
                  variables: {
                    input: { name: "change_online", value: "true" },
                  },
                });

                refetch();
              }}
              disabled={data && data.isOnline.value === "true"}
            >
              Abilita cambio
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                settingUpdate({
                  variables: {
                    input: { name: "change_online", value: "false" },
                  },
                });
                refetch();
              }}
              disabled={data && data.isOnline.value === "false"}
            >
              Disabilita cambio
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive p-1">
        <table id="rates" className="table table-sm align-middle">
          <thead>
            <tr>
              <th>ID</th>
              <th>Da</th>
              <th>Cambio</th>
              <th>A</th>
              <th>Data</th>
              <th>Provider</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate: any) => (
              <tr key={rate.id}>
                <td>{rate.id}</td>
                <td>1 {rate.from_currency}</td>
                <td>{rate.rate}</td>
                <td>{rate.to_currency}</td>
                <td data-sort={rate.date}>
                  {moment
                    .unix(rate.date / 1000)
                    .format(t("global.datetime_format"))}
                </td>
                <td>{rate.provider}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rates;
