import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

// Components
import Account from "../components/Account";
import BankAccounts from "../components/BankAccounts";
import Converter from "../components/Converter";
import Loader from "../components/Loader";
import Orders from "../components/Orders";

const HOME_QUERY = gql`
  query HomeQuery {
    me {
      id
      type
      company_name
      firstname
      lastname
      email
      phone
      street_name
      street_number
      zip_code
      city
      country
      nationality
      birth
      created_at
      updated_at
      bank_accounts {
        iban
        currency_id
        bank_name
        beneficiary
        bic_code
        active
      }
      orders {
        id
        date
        from_currency
        from_amount
        to_currency
        to_amount
        status {
          name
        }
        status_reason {
          name
        }
        bank_account {
          iban
          beneficiary
        }
      }
    }
  }
`;

const Home = () => {
  const { t } = useTranslation();

  const { error, loading, data, refetch } = useQuery(HOME_QUERY);

  if (error) {
    localStorage.removeItem("token");

    return <Redirect to="/login" />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md col-lg-4 col-xl-3">
          <Account me={data.me} refetch={refetch} />
          <BankAccounts
            bank_accounts={data.me.bank_accounts}
            refetch={refetch}
          />
        </div>
        <div className="col-md col-lg-8 col-xl-9 mt-3 mt-md-0">
          <Converter me={data.me} refetch={refetch} />
          <div className="card mb-3">
            <div className="card-body">
              <h3 className="mb-3">{t("home.your_orders")}</h3>
              <Orders orders={data.me.orders} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
