import { gql, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";

// Components
import Loader from "../components/Loader";
import UsersTable from "../components/Users";

const USERS_QUERY = gql`
  query UsersQuery {
    users {
      id
      type
      company_name
      firstname
      lastname
      email
      phone
      street_name
      street_number
      zip_code
      city
      country
      nationality
      birth
      verified
      verified_expiry
      created_at
      updated_at
    }
  }
`;

const Users = () => {
  const { error, loading, data } = useQuery(USERS_QUERY);

  if (error) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.users === null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <h2 className="mt-3">Utenti</h2>
      <UsersTable users={data.users} />
    </div>
  );
};

export default Users;
